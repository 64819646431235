import { LinkGroupContainer } from './styled.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faGithub,
  faMedium,
} from '@fortawesome/free-brands-svg-icons'
import React from 'react'

function LinkGroup() {
  return (
    <LinkGroupContainer>
      <a
        href="https://github.com/EngineLin/"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        href="https://www.facebook.com/YearOfDigitalNomad/"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a
        href="https://medium.com/@linengine/"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        <FontAwesomeIcon icon={faMedium} />
      </a>
    </LinkGroupContainer>
  )
}

export default LinkGroup
