import { lightenDarkenColor } from '@/utils/lightenDarkenColor'

export const theme = {
  mainColor: '#5e31dc',
  mainColorHover: lightenDarkenColor('#5e31dc', 20),

  mainFontSize: '18px',
  mainFontColor: '#666666',
  mainLineHeight: 1.7,

  titleFontColor: '#242f35',
  hintFontColor: '#19abd6',

  spotlight: '#e7305b',

  mainBackgroundColor: 'white',
  buttonBackgroundColor: '#2d383e',
  footerBackgroundColor: '#231c42',
  footerBackgroundColorHover: lightenDarkenColor('#231c42', 20),

  borderColor: '#d8e5f3',

  transitionDuration: '300ms',

  mediaQueryDesktopWide: '1280px',
  mediaQueryDesktop: '980px',
  mediaQueryMobile: '736px',

  padding: '15px',

  zIndexBottom: 1,
  zIndexCenter: 50,
  zIndexTop: 100,
}
